export async function ExportSpreadsheet(api, params, nameFile) {
  const image = await api(params);
  const fileURL = window.URL.createObjectURL(
    new Blob([image], {
      type:
        "application/ vnd.openxmlformats - officedocument.spreadsheetml.sheet; charset = UTF-8"
    })
  );
  const fileLink = document.createElement("a");

  fileLink.href = fileURL;
  fileLink.setAttribute("download", `${nameFile}.xlsx`);
  document.body.appendChild(fileLink);
  fileLink.click();
}
