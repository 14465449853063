export default class AbstractLocalStorage {
    constructor(name) {
        this.name = name;
    }

    /**
     * 
     * @returns {string|object|number|boolean}
     */
    get() {
        if (!this.name) this.constructorError();
        const result = localStorage.getItem(this.name);
        return JSON.parse(result) ?? {};
    }

    /**
     * 
     * @param {string|object|number|boolean} value 
     */
    set(value) {
        if (!this.name) this.constructorError();
        value ?? this.valueError();
        localStorage.setItem(this.name, JSON.stringify(value));
    }

    remove() {
        localStorage.removeItem(this.name);
    }

    constructorError() {
        throw new Error('Constructor is not initialized!');
    }

    valueError() {
        throw new Error('You will need to send a param "value" into a set() function');
    }
}
