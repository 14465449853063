import AbstractLocalStorage from './AbstractLocalStorage';

class UserTokenLocalStorage extends AbstractLocalStorage {
    constructor(name) {
        super();
        this.name = name;
    }    
}

export default new UserTokenLocalStorage('userToken');
