<template lang="pug">
.menu.container
	.flex.justify-between.items-center
		.menu__title

		.flex.justify-between.items-center
			filters(
				name="Store"
				:hasSelectAll="false"
				:items="filters.stores"
				:storageIsFull="storageIsFull"
				:isMainPage="isMainPage"
				:checkedStores="checkedStores.length"
				@update="getStoresFilters"
			)
			filters(
				name="Category"
				:items="filters.categories"
				@update="getCategoriesFilters"
				tree
			)
			filters(
				:class="{'disabled': !isCategoriesChecked || !filters.brands.values.length }"
				name="Brand"
				:items="filters.brands"
				@update="updateState('brands')"
			)
			filters(
				name="Location"
				:items="filters.regions"
			)

			.accuracy__date.flex.items-center.ml-20
				DatePicker(
					is-range
					v-model="rangeDates"
					:max-date="lastDay"
					:min-date="startingDate"
				)
					template(v-slot="{ togglePopover }")
						.date(@click="togglePopover")
							calendar-blank.mr-4(:size="18")
							span {{ formattedDateFrom }}
							span(v-if="isRange") - {{ formattedDateTo }}
				DatePicker(
					v-if="isMainPage"
					is-range
					v-model="rangeDatesSecond"
					:max-date="lastDay"
					:min-date="startingDate"
				)
					template(v-slot="{ updateValue, togglePopover, hidePopover }")
						.date(@click="togglePopover")
							span(v-if="isRangeSecondNull" @click="updateValue($event.target.value)") Сравнить
							template(v-else)
								calendar-blank.mr-4(:size="18")
								span {{ formattedSecondDateFrom }}
								span(v-if="isRangeSecond") - {{ formattedSecondDateTo }}
								svg(
									class="clear-icon"
									viewBox="0 0 24 24"
									stroke="currentColor"
									stroke-width="2"
									@click.stop="removeDate(updateValue, hidePopover)"
								)
									path(d="M6 18L18 6M6 6l12 12")

			button.apply(
				@click="clearFilters"
			) Clear

			button.apply(
				:disabled="!isCheckedFull"
				@click="updateResults"
			) Apply filter

			span.separator

			.flex.justify-between.items-center
				Export.dashboard__export(
					type='white'
					@click="exportHandler"
				)

</template>

<script>
const YESTERDAY = new Date(Date.now() - 2 * 24 * 60 * 60 * 1000)
const STARTING_DATE = new Date(2022, 0, 1)
// const HALF_YEAR_AGO = new Date(Date.now() - 24 * 60 * 60 * 182 * 1000)

import { mapState, mapActions } from 'vuex'
import { DatePicker } from 'v-calendar'
import CalendarBlank from 'vue-material-design-icons/CalendarBlank.vue'
import ConfigInLocalStorage from '@/helpers/LocalStorage/Config'
import FilterInLocalStorage from '@/helpers/LocalStorage/Filter'
import Filters from './Filter.vue'
import Export from '@/components/Nestle/Export/Export.vue'
import { ExportSpreadsheet } from '@/helpers/ExportSpreadsheet'
import { setYMParams } from '@/helpers/YMParams';

export default {
	components: {
		ConfigInLocalStorage,
		FilterInLocalStorage,
		CalendarBlank,
		DatePicker,
		Filters,
		Export
	},
    name: 'GlobalFilter',
	data() {
		return {
			checkedFilters: {
				stores: [],
				categories: [],
				brands: [],
				regions: [],
			},
			filters: {
				stores: {
					name: 'stores',
					values: [],
				},
				categories: {
					name: 'categories',
					values: [],
				},
				brands: {
					name: 'brands',
					values: [],
				},
				regions: {
					name: 'regions',
					values: [],
				},
			},
			rangeDates: {
				start: null,
				end: null,
			},
			rangeDatesSecond: {
				start: null,
				end: null,
			},
			isFetchProcessing: true,
			activeButton: false,
			isMounted: false,
			storageIsFull: false,
			datePickerKey: 0,
		}
	},
	computed: {
		...mapState({
			stores: ({ globalFilter }) => globalFilter.stores,
			categories: ({ globalFilter }) => globalFilter.categories,
			brands: ({ globalFilter }) => globalFilter.brands,
			regions: ({ globalFilter }) => globalFilter.regions,
			secondPeriod: ({ globalFilter }) => globalFilter.secondPeriod,
		}),
		errorsAndFixesUrl() {
			return 'https://docs.google.com/spreadsheets/d/1TiA3t-cb_d0et9J6sfLje5nvcFB_w3WLMcfS7BhEfeQ/edit?usp=sharing'
		},
		additionalLink() {
			let result = null
			if (this.$route.name === 'Nestle.KPIDashboard') {
				result = {
					title: 'Market categories',
					url: 'https://docs.google.com/spreadsheets/d/19H8zUA3iV529ilHaKLZcbwWjES5PSCJ5pIIWmeLPrrQ/edit#gid=0',
				}
			}
			if (this.$route.name === 'Nestle.TaxonomyPlacement') {
				result = {
					title: 'Taxonomy target',
					url: 'https://docs.google.com/spreadsheets/d/19H8zUA3iV529ilHaKLZcbwWjES5PSCJ5pIIWmeLPrrQ/edit#gid=1929339347',
				}
			}
			if (this.$route.name === 'Nestle.ContentIntegrity') {
				result = {
					title: 'Content rules',
					url: 'https://docs.google.com/spreadsheets/d/19H8zUA3iV529ilHaKLZcbwWjES5PSCJ5pIIWmeLPrrQ/edit#gid=917782129',
				}
			}
			return result
		},
		isRange() {
			return Date.parse(this.rangeDates.start) !== Date.parse(this.rangeDates.end);
		},
		isRangeSecond() {
			return Date.parse(this.rangeDatesSecond.start) !== Date.parse(this.rangeDatesSecond.end);
		},
		formattedDateFrom() {
			return this.$utils.format(this.rangeDates.start, 'dd.MM.yyyy')
		},
		formattedDateTo() {
			return this.$utils.format(this.rangeDates.end, 'dd.MM.yyyy')
		},
		isRangeSecondNull() {
			return this.rangeDatesSecond === null || this.rangeDatesSecond.start === null
		},
		formattedSecondDateTo() {
			return this.$utils.format(this.rangeDatesSecond.end, 'dd.MM.yyyy')
		},
		formattedSecondDateFrom() {
			return this.$utils.format(this.rangeDatesSecond.start, 'dd.MM.yyyy')
		},
		lastDay() {
			return YESTERDAY
		},
		startingDate() {
			return STARTING_DATE
		},
		isCheckedFull() {
			return !!(
				this.isStoresChecked &&
				this.isCategoriesChecked &&
				this.isBrandsChecked &&
				this.isRegionsChecked
			)
		},
		checkedStores() {
			return this.filters.stores.values.filter(item => item.checked)
		},
		isStoresChecked() {
			return this.checkedStores.map(item => item.id).join(',')
		},
		isCategoriesChecked() {
			const arrayOfValues = Object.values(this.filters.categories.values)
			const result = [].concat(...arrayOfValues).filter(item => item.checked)
			return result.map(item => item.id).join(',')
		},
		isBrandsChecked() {
			const result =  this.filters.brands.values.filter(item => item.checked)
			return result.map(item => item.id).join(',')
		},
		isRegionsChecked() {
			const result =  this.filters.regions.values.filter(item => item.checked)
			return result.map(item => item.id).join(',')
		},
		isStoresLessLimits() {
			const storeLimits = ConfigInLocalStorage.getLimits('stores')
			return this.checkedStores.length <= storeLimits
		},
		isMainPage() {
			const pattern = ['', '/sokolov', '/sokolov/']
			return pattern.includes(this.$route.path)
		},

	},
	created() {
		const config = {
			limits: {
				stores: 5,
			}
		};
		ConfigInLocalStorage.set(config);
	},
	mounted() {
		this.fetch()
	},
	methods: {
		...mapActions('globalFilter', [
			'fetchStores',
			'fetchCategories',
			'fetchBrands',
			'fetchRegions',
			'saveSecondPeriod',
		]),

		async exportContentIntegrity() {
			const params = this.getParams();

			ExportSpreadsheet(
				this.$api.nestle.getExportContentIntegrity, 
				params,
				'export-contentIntegrity'
			)
				.catch(err => console.error(err));
		},
		async exportPortfolioAvailability() {
			const params = this.getParams();

			ExportSpreadsheet(
				this.$api.nestle.getExportPortfolioAvailability, 
				params,
				'export-portfolioAvailability'
			)
				.catch(err => console.error(err));
		},
		async exportRatingsAndReviews() {
			const params = this.getParams();

			ExportSpreadsheet(
				this.$api.nestle.getExportRatingsAndReviews, 
				params,
				'export-ratingsAndReviews'
			)
				.catch(err => console.error(err));
		},
		async exportImageAccuracy() {
			const params = this.getParams();

			try {
				const image = await this.$api.nestle.getExportImageAccuracy(params)
				const fileURL = window.URL.createObjectURL(
					new Blob(
						[image], 
						{ type: "application/ vnd.openxmlformats - officedocument.spreadsheetml.sheet; charset = UTF-8" }
					)
				)
				const fileLink = document.createElement('a')

				fileLink.href = fileURL
				fileLink.setAttribute('download', 'export-imageAccuracy.xlsx')
				document.body.appendChild(fileLink)
				fileLink.click()
			} catch (error) {
				console.log(error)
			}
		},
		async exportShareOfSearch() {
			const params = this.getParams();

			try {
				const image = await this.$api.nestle.getExportShareOfSearch(params)
				const fileURL = window.URL.createObjectURL(
					new Blob(
						[image], 
						{ type: "application/ vnd.openxmlformats - officedocument.spreadsheetml.sheet; charset = UTF-8" }
					)
				)
				const fileLink = document.createElement('a')

				fileLink.href = fileURL
				fileLink.setAttribute('download', 'export-shareOfSearch.xlsx')
				document.body.appendChild(fileLink)
				fileLink.click()
			} catch (error) {
				console.log(error)
			}
		},

		async exportHandler() {
			if (this.$route.name === 'Nestle.ContentIntegrity') {
				await this.exportContentIntegrity();
			} else if (this.$route.name === 'Nestle.ImageAccuracy') {
				await this.exportImageAccuracy();
			} else if (this.$route.name === 'Nestle.PortfolioAvailability') {
				await this.exportPortfolioAvailability()
			} else if (this.$route.name === 'Nestle.RatingsAndReviews') {
				await this.exportRatingsAndReviews()
			} else if (this.$route.name === 'Nestle.ShareOfSearch') {
				await this.exportShareOfSearch()
			}
		},

		// TODO вынести в модель
		getStorage() {
			const defaultStorage = {
				stores: '',
				categories: '',
				brands: '',
				regions: '',
				dateFrom: '',
				dateTo: '',
			}
			return FilterInLocalStorage.get() || defaultStorage;
		},
		normalizeStorage({
			stores,
			categories,
			brands,
			regions,
		}) {
			return {
				stores: stores.split(',').map(Number) || [],
				categories: categories.split(',').map(Number) || [],
				brands: brands.split(',').map(Number) || [],
				regions: regions.split(',').map(Number) || [],
			}
		},
		// TODO check it
		updateState() {
			const brands = this.filters.brands.values
			this.checkedFilters.brands.splice(0)
			brands.forEach(brand => {
				if(brand.checked) {
					this.checkedFilters.brands.push(Number(brand.id))
				}
			})
		},
		async clearStoresFilter() {
			await this.fetchStores({ categories: this.isCategoriesChecked })
			this.filters.stores.values = this.stores
		},
		async getCategoriesFilters() {
			if (this.isCategoriesChecked) {
				if (this.isStoresChecked) {
					this.getBrands()
				} else {
					this.clearStoresFilter()
					
					const categories =  this.filters.categories.values
					this.checkedFilters.categories = []

					for (let key in categories) {
						categories[key].forEach(category => {
							if(category.checked) {
								this.checkedFilters.categories.push(Number(category.id))
							}
						})
					}
				}
			} else {
				this.clearStoresFilter()
				this.filters.brands.values = []
			}
		},
		getStoresFilters() {
			if (this.isCategoriesChecked) {
				if (this.isStoresChecked) {
					this.getBrands()
				} else {
					this.fetchStores()
					this.getCategories()
					this.filters.brands.values = []
				}
			} else {
				this.getCategories()
			}
		},
		async getBrands() {
			if(this.isStoresChecked && this.isCategoriesChecked) {
				const params = {
					stores: this.isStoresChecked,
					categories: this.isCategoriesChecked,
				}
					
				await this.fetchBrands(params)
				this.filters.brands.values = this.brands
				this.checkAllItems(this.filters.brands.values)
			}
		},		
		initDates(dates) {
			const initialData = {
				dateFrom: YESTERDAY,
				dateTo: YESTERDAY,
			}

			const LSDateFrom = FilterInLocalStorage.getByName('dateFrom')
			const LSDateTo = FilterInLocalStorage.getByName('dateTo')

			// TODO Оптимизировать
			if (!LSDateFrom) {
				FilterInLocalStorage.add('dateFrom', initialData.dateFrom)
				dates.start = YESTERDAY
			} else {
				dates.start = this.$utils.parseISO(LSDateFrom)
			}

			if (!LSDateTo) {
				FilterInLocalStorage.add('dateTo', initialData.dateTo)
				dates.end = YESTERDAY
			} else {
				dates.end = this.$utils.parseISO(LSDateTo)
			}
		},

		prepareArray(checkedArray, arrayOfData) {
			checkedArray.forEach((checkedItem) => {
				arrayOfData.forEach((item) => {
					if (Number(item.id) === Number(checkedItem)) {
						this.$set(item, 'checked', true )
					}
				})
			})

			return arrayOfData
		},

		prepareCategories(checkedArray, arrayOfData) {
			checkedArray.forEach((checkedItem) => {
				Object.values(arrayOfData).forEach((item) => {
					item.forEach(el => {
						if (Number(el.id) === Number(checkedItem)) {
							this.$set(el, 'checked', true)
						}
					})
				})
			})
			return arrayOfData
		},

		checkFulledStorage(storage) {
			return Object.prototype.hasOwnProperty.call(storage, 'stores') &&
				Object.prototype.hasOwnProperty.call(storage, 'categories') &&
				Object.prototype.hasOwnProperty.call(storage, 'brands') &&
				Object.prototype.hasOwnProperty.call(storage, 'regions') &&
				!!storage?.stores.length &&
				!!storage?.categories.length &&
				!!storage?.brands.length &&
				!!storage?.regions.length
		},

		checkAllItems(arr) {
			Object.values(arr).forEach(item => this.$set(item, 'checked', true))
		},
		checkItemsByName(arr, arrayOfNames) {
			arrayOfNames.forEach((name) => {
				Object.values(arr).forEach((item) => {
					if (name === item.name) {
						this.$set(item, 'checked', true)
					}
				})
			})
		},
		async fetch() {
			await this.fetchStores({ categories: this.isCategoriesChecked })
			await this.getCategories()
			
			await this.fetchRegions()

			const rawStorage = this.getStorage()
			this.storageIsFull = this.checkFulledStorage(rawStorage)

			// При 1й загрузке проверяем, есть ли сохраненные значения в LocalStorage
			// Если есть, подгружаем фильтры с учетом LocalStorage
			if (this.storageIsFull) {
				const storage = this.normalizeStorage(rawStorage)
				this.filters.stores.values = this.prepareArray(storage.stores, this.stores)
				this.filters.regions.values = this.prepareArray(storage.regions, this.regions)

				const temporaryCategories = this.filters.categories.values
				this.filters.categories.values = this.prepareCategories(storage.categories, temporaryCategories)

				await this.getBrands()
				const temporaryBrands = this.filters.brands.values
				this.filters.brands.values = this.prepareArray(storage.brands, temporaryBrands)
			} else {
				this.filters.stores.values = this.stores
				this.filters.regions.values = this.regions

				const defaultCheckedRegions = ['Москва']
				this.checkItemsByName(this.filters.regions.values, defaultCheckedRegions)

				if (!this.storageIsFull && this.isMainPage) {
					this.checkAllItems(this.filters.stores.values)
				}
			}

			this.isMounted = true
			this.$emit('check-fulled-storage', this.storageIsFull)
		},
		async getCategories() {
			const newOnj = {}

			try {
				this.isFetchProcessing = true
				const filters = await this.$api.nestle.getParentCategories()
				await this.fetchCategories({ stores: this.isStoresChecked })

				filters.forEach(parentCategory => {
					this.categories.forEach(category => {
						category.filterId.forEach((item) => {
							if(item === +parentCategory.id) {
								if (!Object.prototype.hasOwnProperty.call(newOnj, parentCategory.name)) {
									newOnj[parentCategory.name] = []
								}
								newOnj[parentCategory.name].push({
									id: category.id,
									parentId: parentCategory.id,
									name: category.name,
									checked: category.checked || null,
									filterId: category.filterId,
								})
							}
						})
					})
				})

				const sortedParentCategories = Object.keys(newOnj)
					.sort()
					.reduce((obj, key) => { 
						obj[key] = newOnj[key]; 
						return obj;
					}, {})

				this.filters.categories.values = sortedParentCategories
			} catch(error) {
				console.error(error)
			} finally {
				this.isFetchProcessing = false
			}
		},
		// TODO проверить и оптимизировать
		async clearFilters() {
			this.checkedFilters = {
				stores: [],
				categories: [],
				brands: [],
				regions: [],
			}

			this.filters = {
				stores: {
					name: 'stores',
					values: [],
				},
				categories: {
					name: 'categories',
					values: [],
				},
				brands: {
					name: 'brands',
					values: [],
				},
				regions: {
					name: 'regions',
					values: this.filters.regions.values,
				},
			}

			this.fetch()

			FilterInLocalStorage.remove()
			
			FilterInLocalStorage.add('dateFrom', YESTERDAY)
			FilterInLocalStorage.add('dateTo', YESTERDAY)
			this.rangeDates = {
				start: YESTERDAY,
				end: YESTERDAY
			}

			this.activeButton = true
		},

		getParams() {
			const rawStorage = this.getStorage()

			const result = {
				stores: rawStorage.stores,
				categories: rawStorage.categories,
				brands: rawStorage.brands,
				regions: rawStorage.regions,
				dateFrom: this.$utils.format(this.$utils.parseISO(rawStorage.dateFrom)) || null,
				dateTo: this.$utils.format(this.$utils.parseISO(rawStorage.dateTo)) || null,
			}
			return result
		},

		updateResults() {
			this.activeButton = false

			if (this.isMainPage && !this.isStoresLessLimits) {
				const params = {
					stores: this.isStoresChecked,
					categories: this.isCategoriesChecked,
					brands: this.isBrandsChecked,
					regions: this.isRegionsChecked,
					dateFrom: this.$utils.format(this.rangeDates.start),
					dateTo: this.$utils.format(this.rangeDates.end),
				}

				if (
					this.rangeDatesSecond !== null
					&& Object.prototype.hasOwnProperty.call(this.rangeDatesSecond, 'start')
					&& this.rangeDatesSecond.start !== null
				) {
					params.dateFrom = this.$utils.format(this.rangeDatesSecond.start)
					params.dateTo = this.$utils.format(this.rangeDatesSecond.end)

					this.saveSecondPeriod({
						from: params.dateFrom,
						to: params.dateTo,
					})
					this.$emit('update-filters', params, true)
				} else {
					this.$emit('update-filters', params)
				}

				this.$emit('filter-status', this.isCheckedFull)
			} else {
				FilterInLocalStorage.add('stores', this.isStoresChecked)
				FilterInLocalStorage.add('categories', this.isCategoriesChecked)
				FilterInLocalStorage.add('brands', this.isBrandsChecked)
				FilterInLocalStorage.add('regions', this.isRegionsChecked)

				FilterInLocalStorage.add('dateFrom', this.rangeDates.start)
				FilterInLocalStorage.add('dateTo', this.rangeDates.end)
				
				const params = this.getParams()

				if (
					this.rangeDatesSecond !== null
					&& Object.prototype.hasOwnProperty.call(this.rangeDatesSecond, 'start')
					&& this.rangeDatesSecond.start !== null
				) {
					params.dateFrom = this.$utils.format(this.rangeDatesSecond.start)
					params.dateTo = this.$utils.format(this.rangeDatesSecond.end)

					this.saveSecondPeriod({
						from: params.dateFrom,
						to: params.dateTo,
					})
					this.$emit('update-filters', params, true)
				} else {
					this.$emit('update-filters', this.getParams())
				}

			}

			setYMParams({
					Ритейлы:  this.checkedStores.map(store => store.name),
			});
		},

		removeDate(updateFn, hideFn) {
			this.rangeDatesSecond.start = null;
			this.rangeDatesSecond.end = null;

			updateFn({ start: null, end: null })
			this.datePickerKey++;

			hideFn();
		},
	},

	watch: {
		rangeDates: {
			immediate: true,
			handler(value) {
				if (!value.end && !value.start) {
					this.initDates(this.rangeDates)
				} else {
					FilterInLocalStorage.add('dateFrom', value.start)
					FilterInLocalStorage.add('dateTo', value.end)
				}
				this.activeButton = true
			}
		},
		'$route.path': {
			deep: true,
			immediate: true,
			handler() {
				if ((!this.isMainPage && this.isStoresLessLimits) || this.isMainPage) {
					this.$emit('update-filters', this.getParams())
				}
			},
		},
	},
}
</script>

<style lang="scss" scoped>

.date {
	display: flex;
}

.menu {
	width: 100%;
	padding: 12px 24px;
	background-color: color(gray-800);

	&__title {
		min-height: 32px;
		color: color(white);
		font-size: 24px;
		line-height: 1.33;

		&.--nestle {
			width: 280px; // todo for demo only
		}
	}
	&__nav {
		width: 640px; // todo for demo only

		&-wrap {
			max-width: 450px;
		}
	}
	&__item {
		font-size: 12px;
		color: color(white);
	}
}


.accuracy {
	&__nav {
		width: 190px;
	}
	&__date {
		display: flex;
		align-items: baseline;
		flex-direction: column;
		color: color(white);
		cursor: pointer;
	}
	&__section {
		padding: 20px;
		background-color: color(white);
		border-radius: 4px;
	}
	&__chart {
		::v-deep .chart {
			height: 340px;
		}
	}
	&__product {
		width: 32%;
	}
}
.header {
	margin-bottom: 32px;

	&-right {
		margin-left: auto;
	}
}
.filter {
	&__list {
		max-height: 200px;
		overflow: auto;
	}

	&__label {
		margin-left: 10px;
		padding: 0 4px 0 10px;
		outline: 1px color(gray-400) solid;
		border-radius: 3px;
		color: color(gray-700);
		background-color: color(white);
		cursor: pointer;

		&-icon {
			margin-left: 4px;
		}
	}

	&__bottom {
		margin-top: 15px;
		padding-top: 5px;
		border-top: 1px solid color(gray-300);
	}
	&__clear {
		color: color(blue-bright);
		cursor: pointer;
	}
	&__apply {
		padding: 4px 10px 5px 10px;
		background-color: color(blue-bright);
		color: color(white);
		cursor: pointer;
		border-radius: 4px;
	}
}

select {
	padding: 0 5px;
	outline: 1px color(gray-400) solid;
	border-radius: 3px;
	color: color(gray-700);

	&:focus {
		outline: none;
	}
}

.apply {
	margin-left: 20px;
	border: 1px solid #ffffff;
	padding: 2px 5px;
	cursor: pointer;
	color: #fff;

	border-radius: 4px;

	border-radius: 3px;
	&:disabled {
		cursor: not-allowed;
		color: gray;
		background-color: lightgray;
		border-color: lightgray;
	}
	&:focus {
		border: 1px solid black;
		outline: inherit;
	}
}
.disabled {
	pointer-events: none;

	::v-deep.filter__label {
		color: gray;
	}
}
.additional-link {
	color: #ffffff;
	margin-right: 20px;
}
.clear-icon {
	width: 18px;
	height: 18px;
	&:hover {
		stroke: #fc727f;
	}
}
.separator {
    display: block;
    width: 1px;
    height: 27px;
    background-color: rgba(255, 255, 255, 0.4);
    margin-left: 24px;
    margin-right: 24px;
}
</style>
