import AbstractLocalStorage from './AbstractLocalStorage';

class ConfigLocalStorage extends AbstractLocalStorage {
    constructor(name) {
        super();
        this.name = name;
    }

    getLimits(name) {
        const { limits } = this.get();
        if (!Object.hasOwnProperty.call(limits, name)) return;
        return limits[name];
    }
}

export default new ConfigLocalStorage('filter-config');
